import Vue from "vue";
import vueCustomElement from "vue-custom-element";
import * as components from "@uniqgmbh/holidayguru-components";

import HgNavbarWidget from "../src/widgets/Navbar.vue";
import HgSimpleNavbarWidget from "../src/widgets/SimpleNavbar.vue";
import HgFooterWidget from "../src/widgets/Footer.vue";
import "../src/styles/index.css";

Vue.config.productionTip = false;

// Font Awesome
import "../src/plugins/font-awesome";

// i18n
import { i18n } from "../src/plugins/i18n";

// Apollo
// import { apolloProvider } from "./plugins/apollo";

// Styles
import "../src/styles/index.css";

HgNavbarWidget.i18n = i18n;
HgSimpleNavbarWidget.i18n = i18n;
HgFooterWidget.i18n = i18n;

Object.keys(components).forEach(componentName => {
  Vue.component(componentName, components[componentName]);
});

// Use Plugins
Vue.use(vueCustomElement);

// Define Element
Vue.customElement("hg-navbar-widget", HgNavbarWidget);
Vue.customElement("hg-simple-navbar-widget", HgSimpleNavbarWidget);
Vue.customElement("hg-footer-widget", HgFooterWidget);

setTimeout(() => {
  if (document) {
    // document.body.classList.add("holidayguru");
    if (document.querySelector(".vue-portal-target")) {
      document.querySelector(".vue-portal-target").classList.add("holidayguru");
    }
    document.querySelector("html").style.fontSize = "16px";
  }
}, 300);
